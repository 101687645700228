<template>
	<div>
		<!-- <around :cate="2"></around> -->
		<leaflet-around :cate="2" ></leaflet-around>	
	</div>
</template>

<script>
// import around from '@/components/data/around.vue'
import leafletAround from '@/components/leaflet/leafletAround.vue'
export default {
	name: 'FrontendPcBigdataFenxian',
	components:{leafletAround},

};
</script>

<style lang="scss" scoped>

</style>